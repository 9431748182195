// prettier-ignore
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { height } from "@mui/system";
import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { MyStory } from "./pages/MyStory";
import { Sightings } from "./pages/Sightings";
import { WhatIsHe } from "./pages/WhatIsHe";
import { withRoot } from "./withRoot";


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
		overflowY: "scroll",
		fontFamily: 'cursive',
		background: 'black'
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 56px)",
		marginTop: 106,
	},
}));


// 50% goat 50% demon 100% lover

function App() {

	const classes = useStyles();

	return (
		<Router>
			<div className={classes.root}>
				<div className={classes.appFrame}>

				 <Routes>
				 <Route path="/" element={<Landing />} />

        <Route path="/my-story" element={<MyStory />} />
		<Route path="/sightings" element={<Sightings  />} />
		<Route path="/what-is-he" element={<WhatIsHe  />} />


      </Routes> 

				</div>
			</div>
		</Router>
	);
}


export default withRoot(App);
