import React from "react";
import { get as _get } from 'lodash';
import { Grid, Typography } from "@mui/material";



export function WhatIsHe() {


    return (
        <Grid style={{ margin: '1em' }} container>

            <Grid item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="h3">What is HE?</Typography>

            </Grid>



            <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="body1">In South Jersey and Philadelphia folklore in the United States, the Jersey Devil (also known as the Leeds Devil) is a legendary creature said to inhabit the forests of the Pine Barrens in South Jersey.[1] The creature is often described as a flying biped with hooves, but there are many variations. The common description is that of a bipedal kangaroo-like or wyvern-like creature with a horse- or goat-like head, leathery bat-like wings, horns, small arms with clawed hands, legs with cloven hooves, and a forked or pointed tail. It has been reported to move quickly and is often described as emitting a high-pitched "blood-curdling scream".</Typography>

            </Grid>


            <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/whatishe1.jpg"></img>
						</Grid>


                        <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="body1">There have been many claims of sightings and occurrences involving the Jersey Devil.

According to legend, while visiting the Hanover Mill Works to inspect his cannonballs being forged, Commodore Stephen Decatur sighted a flying creature and fired a cannonball directly upon it, to no effect.[2]

Joseph Bonaparte, elder brother of Napoleon, is also claimed to have seen the Jersey Devil while hunting on his Bordentown estate about 1820.[14]

During 1840, the Jersey Devil was blamed for several livestock killings. Similar attacks were reported during 1841, accompanied by tracks and screams.[2]

In Greenwich Township, in December 1925, a local farmer shot an unidentified animal as it attempted to steal his chickens, and then photographed the corpse. Afterward, he claimed that none of 100 people he showed it to could identify it.[15] On July 27, 1937, an unknown animal "with red eyes" seen by residents of Downingtown, Pennsylvania was compared to the Jersey Devil by a reporter for the Pennsylvania Bulletin of July 28, 1937.[16] In 1951, a group of Gibbstown, New Jersey boys claimed to have seen a 'monster' matching the Devil's description[17] and claims of a corpse matching the Jersey Devil's description arose in 1957.[18] During 1960, tracks and noises heard near Mays Landing were claimed to be from the Jersey Devil.[19] During the same year the merchants around Camden offered a $10,000 reward for the capture of the Jersey Devil, even offering to build a private zoo to house the creature if it was captured.[</Typography>

            </Grid>


            <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/whatishe2.jpg"></img>
						</Grid>



                        <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="body1">Skeptics believe the Jersey Devil to be nothing more than a creative manifestation upon the imaginations of the early English settlers in South Jersey, with plausible natural explanations including: bogeyman stories created and told by bored Pine Barren residents as a form of children's entertainment; the byproduct of the historical local disdain for the Leeds family; the misidentification of known animals; and rumors based on common negative perceptions of the local rural population of the Pine Barren (known as "pineys").[27]

The frightening reputation of the Pine Barrens may indeed have contributed to the Jersey Devil legend. Historically, the Pine Barrens was considered inhospitable land. Gangs of highwaymen, such as the politically disdained Loyalist brigands, known as the Pine Robbers, were known to rob and attack travelers passing through the Barrens. During the 18th century and the 19th century, residents of the isolated Pine Barrens were deemed the dregs or outcasts of society: poor farmers, fugitives, brigands, Native Americans, poachers, moonshiners, runaway slaves, and deserting soldiers. So-called pineys have sometimes fostered certain frightening stories about themselves and the Pine Barrens to discourage outsiders or intruders from entering the Barrens. Pineys were further demonized and vilified after two eugenics studies were published during the early 20th century, which depicted pineys as congenital idiots and criminals, as seen in the research performed on "The Kallikak Family" by Henry H. Goddard, which is now considered biased, inaccurate, unscientific, and, most likely, falsified.[28]

Due in part to their isolated and undeveloped nature, the Pine Barrens have themselves fostered various folk legends.[29] Apart from the Jersey Devil, many other legends are associated with the Pine Barrens; supernatural creatures and ghosts said to haunt the pine forests include the ghost of the pirate Captain Kidd, who supposedly buried treasure in the Pine Barrens and is sometimes allegedly seen in the company of the Jersey Devil; the ghost of the Black Doctor, the benevolent spirit of an African-American doctor who, after being forbidden from practicing medicine due to his race, entered the Pine Barrens to practice medicine in the isolated communities of the Barrens and is said to still come to the aid of lost or injured travelers; the ghost of the Black Dog, which, unlike many black dog legends, is usually portrayed as harmless; the ghost of the Golden-Haired Girl, the spirit of a girl who is said to be staring out into the sea, dressed in white, mourning the loss of her lover at sea; and the White Stag, a ghostly white deer said to rescue travelers in the Barrens from danger.[29][30][31][32] There are also folk tales concerning the Blue Hole, an unusually clear blue and rounded body of water located in the Pine Barrens between Monroe Township, Gloucester County and Winslow Township, Camden County and often associated with the Jersey Devil.</Typography>

            </Grid>


            <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/whatishe3.jpg"></img>
						</Grid>


                    

        </Grid>
    );
}

