import * as React from "react";
import App from "./App";
import { CookiesProvider } from 'react-cookie';


export function ReduxRoot() {
	return (
		<CookiesProvider>
		<App />
		</CookiesProvider>
	);
}
