import React from "react";
import { get as _get } from 'lodash';
import { Grid, Typography } from "@mui/material";



export function MyStory() {


    return (
        <Grid style={{ margin: '1em' }} container>

            <Grid item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="h3">Nothing will ever be the same after that warm summer night</Typography>

            </Grid>



            <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="body1">It all started when I was 19 years old, and decided to take a camping trip to the famous Pine Barrens of New Jersey. It was a solo expedition, lasting two weeks. At the time I was not a believer in the supernatural, but my life was about to be forever changed. Going into my trip, I had heard stories of a thing that lurked in the woods. When I stopped for gas just before entering the Barrens, but when I went to pay, the attendant said I had best be careful, because I was "just his type". When I asked what that meant, she just told me that the Jersey Devil would spot me a mile away and I had best be ready.</Typography>

            </Grid>

            
            <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="body1">

                    I foolishly (or luckily) ignored the warnings of the gas station attendant, and continued my trip. The first couple of nights were uneventful, and the New Jersey forests were as beautiful as they are said to be, but then everything changed on that third night...
                </Typography>

            </Grid>

            <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/MyStory.jpg"></img>
						</Grid>


                        
            <Grid style={{marginTop: '1em'}} item>
                <Typography style={{ height: '-webkit-fill-available', fontFamily: 'cursive', color: 'white' }} variant="body1">

                   I had just finished eating a late dinner, and was getting ready for bed when I heard the snuffling and grunting of a large animal. Thinking that it must be a moose, I decided to go into my tent, and hope that it would pass if I didn't bother it. Boy was I wrong. As it got closer, I could see through the shadow of the firelight, that it was no moose, but a grotesque monster, half man, half beast, and later I would find out 100% lover. I watched as it approached the tent, and to my horror began to unzip the flap. I closed my eyes and waited for death, but to my shock and pleasure, that is not what I found. Instead, what began was the most intimate and animalistic night of lovemaking I have ever had. But when I awoke the next morning, he was gone without a trace. Every since then, I have sought him out to no success.
                </Typography>

            </Grid>


        </Grid>
    );
}

