import React from "react";
import { get as _get } from 'lodash';
import { Grid, Typography } from "@mui/material";



export function Sightings() {


    return (
        <Grid style={{ margin: '1em' }} container>

            <Grid item>
                <Typography style={{ fontFamily: 'cursive', color: 'white' }} variant="h3">EVIDENCE!!!!!</Typography>

            </Grid>

            <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/sighting1.jpg"></img>
						</Grid>


                        <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/sighting2.jpg"></img>
						</Grid>


                        <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/sighting3.jpg"></img>
						</Grid>


                        <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/sighting4.jpg"></img>
						</Grid>


                        <Grid style={{marginTop: '1em'}} xs={12} item>
							<img style={{ width: '80%' }} src="data/sighting5.jpg"></img>
						</Grid>


        </Grid>
    );
}

