import React from "react";
import { get as _get  } from 'lodash';
import { Grid, Typography } from "@mui/material";



export function Landing() {


  return (
    <Grid style={{ margin: '1em' }} container>
						<Grid xs={12} item>

						<Typography style={{ color: 'red', fontFamily: 'cursive' }} variant="h3">
								The Jersey Devil is Real!!!!!!!
							</Typography>
						
						</Grid>


						<Grid xs={12} item>
							<img style={{ width: '80%' }} src="data/JerseyDevil.png"></img>
						</Grid>

						<Grid xs={12} item>
						<Typography variant="h4" style={{ fontFamily: 'cursive', color: 'white' }} >
								I wasn't a believer.... until he ate my ass
							</Typography>
						</Grid>




						<Grid xs={12}  item>
							<ul>
								<li style={{color: 'white'}}>
									<a href="/my-story">My Story</a>

								</li>
								<li style={{color: 'white'}}>
									<a href="/sightings">Sightings</a>

								</li>	<li style={{color: 'white'}}>
									<a href="/what-is-he">What is He?</a>

								</li>
							</ul>


						</Grid>


						<Grid xs={12}  item>
							<img style={{ width: '80%' }} src="data/trees.jpg"></img>
						</Grid>


						
						<Grid xs={12} style={{  paddingTop: '3rem' }} item>
							<img style={{ width: '80%' }} src="data/real.jpg"></img>
						</Grid>



						<Grid xs={12} item>
						<Typography variant="h4" style={{ height: '-webkit-fill-available', fontFamily: 'cursive', color: 'white' }} >
								The search continues...
							</Typography>
						</Grid>

					</Grid>
  );
}

